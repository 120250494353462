.app-ColumnsForm {
  @row-height: 32px;
  @padding: 12px;

  .row-styles() {
    height: @row-height;
    margin-bottom: @padding;
  }

  .column-styles() {
    flex: 0 0 170px;
    padding: 0 12px;
    border-right: 1px solid @qbi-color-9;

    &__header {
      .row-styles();
      font-weight: bold;
      display: flex;
      align-items: center;
    }

    &__row {
      .row-styles();
      .ant-select {
        width: 100%;
      }
      .ant-radio-group {
        margin-top: 8px;
      }
    }

    &__input {
      &--error {
        border-color: @qbi-color-red;
      }
    }

    &__drag-handle {
      .grabbable();
      margin-right: 10px;
      width: 18px;
      height: 15px;
      opacity: 0.25;
      // Background styles for 3 horizontal bars.
      background: linear-gradient(
        180deg,
        #000,
        #000 20%,
        #fff 0,
        #fff 40%,
        #000 0,
        #000 60%,
        #fff 0,
        #fff 80%,
        #000 0,
        #000
      );
    }
  }

  &__content {
    display: flex;
  }

  &__headers {
    flex: 0 0 150px;
    padding: 0 12px;
    border-right: 1px solid @qbi-color-9;
    text-align: right;
    font-weight: bold;
  }

  &__row {
    .row-styles();
    line-height: @row-height;
  }

  &__info-icon {
    margin-left: 5px;
    cursor: pointer;
  }

  &__container {
    flex: 1;
    display: flex;
    overflow: auto;
    padding-bottom: 30px;

    .app-Column {
      .column-styles();
    }
  }

  &__actions {
    .modal-footer();
  }

  &__helper {
    z-index: @zindex-modal + 5;
    padding: 0 @padding;
    background-color: @qbi-color-white;
    pointer-events: auto !important; // Workaround to make the grabbing cursor work
    cursor: grabbing !important; // Workaround to make the grabbing cursor work
    .app-Column {
      .column-styles();
      &__drag-handle {
        cursor: grabbing !important; // Workaround to make the grabbing cursor work
      }
    }
  }
}
