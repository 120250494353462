.app-PivotView {
  height: 100%;

  &__loading {
    .loading(@front-primary-color);
  }

  .app-PivotTable {
    height: 100%;
  }
}
