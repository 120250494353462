.app-LoginTopbar {
  @narrow-menu-limit: 991px;
  @mobile-menu-limit: 767px;

  height: @login-topbar-height;

  @media (max-width: @mobile-menu-limit) {
    height: auto;
  }

  .topbar-container() {
    width: 940px;
    padding: 0;
    margin-left: auto;
    margin-right: auto;

    @media (max-width: @narrow-menu-limit) {
      width: 100%;
      max-width: 728px;
    }

    @media (max-width: @mobile-menu-limit) {
      width: 100%;
      padding-left: 10px;
      padding-right: 10px;
    }
  }

  &__container {
    .topbar-container();
    position: relative;
    display: flex;
    justify-content: space-between;
  }

  &__logo {
    width: 100%;
    height: 100%;
    max-height: 45px;
    max-width: 42px;
    margin-top: 8px;
  }

  &__navigation {
    &-link {
      font-size: 16px;
      font-weight: 500;
      color: @qbi-color-2;
      letter-spacing: 0;
      line-height: @login-topbar-height;
      padding: 20px 24px;

      &:hover {
        color: @qbi-color-blue;
      }
    }

    &-icon {
      font-size: 14px !important;
      margin-left: 6px;
      margin-right: -2px;
    }
  }

  &__service-links {
    background-color: #fff;
    border: 1px solid @qbi-color-blue;
    border-radius: 10px;
    box-shadow: 1px 1px 8px 0 rgba(0, 0, 0, 0.29);
  }

  &__login-link {
    .login-page-button-white();
    padding: 7px 26px;
  }

  &__desktop-menu {
    display: block;
    @media (max-width: @mobile-menu-limit) {
      display: none;
    }
  }

  &__mobile-menu-toggle {
    .reset-button();
    display: none;
    position: absolute;
    right: 0px;
    top: 0px;
    width: @login-topbar-height;
    height: @login-topbar-height;
    text-align: center;
    @media (max-width: @mobile-menu-limit) {
      display: inline-block;
    }

    .anticon {
      font-size: 16px;
    }

    &--open,
    &--open:hover,
    &--open:focus {
      background-color: @qbi-color-blue;
      .anticon {
        color: #fff;
      }
    }
  }

  &__mobile-menu {
    display: none;
    .ant-collapse-item {
      border-bottom: none !important;
    }

    .ant-collapse-header {
      padding-bottom: 0 !important;
    }

    .ant-collapse-content-box {
      padding: 0 !important;
    }

    .app-LoginTopbar__navigation {
      .topbar-container();
      padding: 0 !important;
    }

    .app-LoginTopbar__navigation-link {
      display: block;
      padding: 0 24px;
      height: 60px;
      line-height: 60px;
    }

    .app-LoginTopbar__login-link {
      border: none;
      border-radius: 0;
    }

    @media (max-width: @mobile-menu-limit) {
      display: block;
    }
  }
}
