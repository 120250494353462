.app-Sidebar {
  @icon-margin: 20px;
  @item-height: 45px;
  @menu-text-color: @front-sidebar-text-color;
  @title-background-color: @front-sidebar-background;
  @submenu-background-color: @front-sidebar-background-lighter;
  @title-height: 55px;

  position: fixed;

  .menu-colors(
    @menu-background: @qbi-background-darker;
    @submenu-background: @qbi-background-dark;
    @menu-text-color: @qbi-color-10;
    @menu-item-background-active: @front-primary-color;
    @menu-item-text-color-active: @qbi-color-white;
    @menu-item-text-color-hover: @qbi-color-8
  );

  .ant-layout-sider-children {
    overflow-y: auto;
  }

  .ant-layout-sider-trigger {
    background-color: @title-background-color;
  }

  .ant-menu-inline-collapsed {
    border: none;
    text-align: center;

    .ant-menu-submenu-selected {
      color: @front-primary-color;
    }
  }
  .ant-menu-submenu-title {
    text-transform: uppercase;
    margin-top: 0;
    margin-bottom: 0;
    padding-right: 28px;

    .ant-menu-submenu-arrow {
      right: @sidebar-padding;
    }
  }
  .ant-menu-submenu-selected {
    color: @qbi-color-10;
  }
  .ant-menu-item {
    &.ant-menu-item-selected {
      &::after {
        display: none;
      }
    }
  }
  .ant-menu-submenu-title,
  .ant-menu-item {
    .anticon {
      font-size: 15px;
      margin-right: @icon-margin;
    }
  }
  .ant-menu-inline .ant-menu-submenu-title {
    height: @title-height;
    line-height: @title-height;
    margin-top: 0;
    margin-bottom: 0;
  }
  .ant-menu.ant-menu-sub.ant-menu-inline > .ant-menu-item {
    height: @item-height;
    line-height: @item-height;
    margin-top: 0;
    margin-bottom: 0;
  }
  .ant-menu.ant-menu-inline > .ant-menu-item {
    height: @title-height;
    line-height: @title-height;
    margin-top: 0;
    margin-bottom: 0;
  }
  .ant-menu-submenu-inline > .ant-menu-submenu-title::after {
    .large-icon();
  }
  .ant-menu-inline-collapsed > .ant-menu-submenu > .ant-menu-submenu-title {
    padding: 4px 22px !important;
    height: 50px;
    line-height: 50px;

    .anticon {
      font-size: 20px;
    }
  }
  .ant-layout-sider {
    background-color: @submenu-background-color;
  }

  // Indentations
  .ant-menu-submenu {
    .ant-menu-item {
      padding-left: 22px !important;
      padding-right: 28px !important;
    }
    .ant-menu-submenu-title {
      padding-left: @sidebar-padding !important;
    }
  }

  // Hide icon in the normal view (but not in collapsed view).
  .ant-menu-submenu-inline > .ant-menu-submenu-title .anticon {
    display: none;
  }

  transition: unset;
  * {
    transition: unset !important;
  }

  &-loader {
    padding-top: 20px;
    padding-left: 20px;
  }

  &-not-mobile {
    overflow-y: auto;
    height: calc(~'100vh - ' @layout-header-height);

    .mobile({
      display: none;
    });
  }
  &-mobile {
    position: fixed;
    z-index: 10;
    overflow-y: auto;
    height: calc(~'100vh - ' @layout-header-height);

    &-collapsed {
      display: none;
    }

    .not-mobile({
      display: none;
    });
  }
}

// Lock icon styles
// ----------------

.app-Sidebar__vg-lock-icon {
  position: absolute;
  top: 15px;
  right: 0;
  font-size: 11px !important;
}

// This places the icon correctly inside the sidebar.
// When the sidebar is collapsed, the lock icon is not inside the sidebar itself.
.app-Sidebar {
  .app-Sidebar__vg-lock-icon {
    top: 17px !important;
    right: -8px !important;
  }
}
