.app-ViewTopbar {
  @topbar-horizontal-padding: 20px;
  @select-height: 20px;

  height: @view-topbar-height;
  display: flex;
  background-color: @view-topbar-background;
  color: @view-topbar-text-color;

  &__header {
    line-height: @view-topbar-height;
    font-size: @qbi-font-size-big;
    margin: 0;
    margin-right: @topbar-horizontal-padding;
    padding-left: @topbar-horizontal-padding;
    padding-right: @topbar-horizontal-padding;
    color: @view-topbar-text-color;
    border-right: 1px solid @view-topbar-divider-color;
  }

  &__select {
    line-height: @view-topbar-height;
    .ant-select {
      width: 250px;
      height: @select-height;
      .ant-select-selection {
        height: @select-height;
        &__rendered {
          line-height: @select-height - 2px;
        }
      }
    }
  }

  &__changer-text {
    display: inline-block;
    margin-right: 12px;
    color: @view-topbar-text-color;
    font-size: @qbi-font-size;
  }

  &__switch {
    line-height: @view-topbar-height;

    &-label {
      display: inline-block;
      margin-left: 12px;
      color: @view-topbar-text-color;
      font-size: @qbi-font-size;
    }

    &-on &-status {
      color: @front-primary-color;
      text-transform: uppercase;
    }

    .ant-switch {
      position: relative;
      top: -1px;
      height: @select-height;
      background-color: @qbi-color-11;
      &:before,
      &:after {
        background-color: @front-sidebar-background-lighter;
        width: @select-height - 4px;
        height: @select-height - 4px;
      }
    }

    .ant-switch-checked {
      &:before,
      &:after {
        background-color: @front-primary-color;
      }
    }
  }
}
