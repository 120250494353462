@import './SourceTableGroup/source-table-group';

.app-SourceTables {
  mark {
    padding: 0;
    background-color: yellow;
    color: black;
  }

  &__search-container {
    padding: 12px;
    background-color: @fox-sidebar-background;
  }

  &__loading {
    .loading(@qbi-color-9);
    padding-top: 12px;
    > .ant-spin {
      margin-right: 0;
    }
  }
}
