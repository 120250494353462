.app-Table {
  height: 100%;
  position: relative;

  &-loading {
    .loading();
  }

  &__empty-message {
    padding: 16px;
  }
}
