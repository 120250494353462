.app-WorkspaceEditor {
  text-align: center;
  margin-top: 20px;
  margin-bottom: 20px;

  .ant-btn {
    &:hover,
    &:focus {
      color: @front-primary-color;
      border-color: @front-primary-color;
    }
    &:active {
      color: darken(@front-primary-color, 20%);
      border-color: darken(@front-primary-color, 20%);
    }
  }

  &__modal {
    top: 20px;
  }

  &__sidebar-col {
    height: 100%;
    border-right: 1px solid @modal-border-color;
  }
}

.app-ErrorMessages {
  padding-bottom: 20px;
}

.app-SidebarEditor {
  height: 430px;
  margin-bottom: 24px;

  &__header {
    font-weight: bold;
    line-height: 24px;
    margin-bottom: 14px;
  }

  &__add-category-button {
    float: right;
    height: 24px;
    .ant-button-color(@qbi-color-blue-light);
  }

  &__node {
    width: 420px;

    &-input {
      width: 160px;
    }

    &-view-count {
      margin-left: 12px;
      font-size: @qbi-font-size-small;
      font-weight: normal;
    }

    &-add-group-button {
      margin-right: 4px;
      font-size: 18px;
      line-height: 24px;
      .ant-button-color(@qbi-color-green);
    }

    &-remove-button {
      .anticon {
        margin-top: 2px;
      }
    }

    &--active {
      box-shadow: 0px 0px 3px 3px @primary-color;
    }
  }

  &__category {
    .rst__moveHandle {
      background-color: @qbi-color-blue-light;
    }
  }

  &__group {
    cursor: pointer;

    .rst__moveHandle {
      background-color: @qbi-color-green;
    }
  }
}

.app-TeamAccessSelect {
  margin-top: 6px;
  width: 100%;
}

.app-ViewGroupEditor {
  &__header {
    font-weight: bold;
    line-height: 24px;
    margin-bottom: 14px;
  }

  &__subheader {
    font-weight: bold;
  }

  .ant-form-item + .ant-form-item {
    margin-right: 0;
  }

  &__help-text {
    margin-top: 8px;
    font-style: italic;
  }

  &__divider {
    margin: 12px 0;
  }
}

.app-ViewsEditor {
  // Hardcoded to match the sidebar editor tree height.
  height: 282px;

  &__header {
    font-weight: bold;
    line-height: 24px;
    margin-bottom: 14px;
  }

  &__add-view-button {
    height: 24px;
    float: right;
    .ant-button-color(@qbi-color-orange);
  }

  &__node {
    width: 400px;
    .rst__moveHandle {
      background-color: @qbi-color-orange;
    }
  }
}

.app-ViewEditor {
  &__form-item {
    margin-bottom: 0;
    .ant-form-item-label,
    .ant-form-item-control {
      line-height: 30px;
    }
  }
}
