.app-TeamAccessSelect {
  @edit-height: 24px;

  &__visibility-text {
    display: inline-block;
    font-size: 10px;
  }

  &__edit-button {
    margin-left: 6px;
  }

  &__edit {
    display: flex;
    width: 400px;
    align-items: center;
  }

  &__checkbox {
    line-height: @edit-height;
  }

  &__select {
    flex: 1;
    margin-left: 4px;
    line-height: @edit-height;
  }
}
