@import './MeasureSelect/measure-select';

.app-PivotTable {
  overflow-y: auto;

  .dx-widget {
    font-size: @font-size-base;
  }

  &-hidden {
    display: none;
  }

  &-settings {
    padding-left: 13px;
    padding-right: 10px;
    font-size: 20px;
    cursor: pointer;
  }

  &-settings-dropdown {
    .dropdown();
    top: 0;
  }

  &-tool-panel {
    display: flex;
    align-items: center;
    padding-top: 4px;
    padding-bottom: 4px;

    &-hidden {
      height: 0px !important;

      .app-PivotTable-settings {
        position: relative;
        top: 19px;
        z-index: 1;
      }
    }
  }

  &-chart-select {
    min-width: 150px;
    margin-left: 10px;
    color: @qbi-color-black;
  }

  &-grid-container {
    display: flex;

    &-no-panel {
      margin-left: 5px;
    }
  }

  .dx-header-filter:not(.dx-header-filter-empty) {
    color: @active-filter-color !important;
  }

  &-field-chooser {
    flex-basis: 200px;
    width: 200px;
    min-width: 200px;

    .dx-pivotgridfieldchooser-container {
      > .dx-row:first-child {
        display: none;
      }

      > .dx-row:nth-child(2) {
        > .dx-col:first-child {
          width: 100%;
          float: none;

          > .dx-area {
            height: auto;
            padding-top: 0;

            .dx-area-fields-header {
              display: none;
            }

            .dx-area-fields {
              height: auto;
              margin-top: 0;
            }
          }
        }
        > .dx-col:nth-child(2) {
          display: none;
        }
      }
    }
  }
}
