.app-DataExports {
  position: relative;

  &__loading-wrapper {
    position: absolute;
    left: 0;
    right: 0;
  }

  &__loading {
    .loading(@qbi-color-9);
    padding-top: 12px;
    > .ant-spin {
      margin-right: 0;
    }
  }

  .sidebar-collapse-panel(
    @hover-selector: ~'ant-collapse-header'
  );

  .ant-collapse-content-box {
    padding: 0 !important;
  }

  .ant-collapse .ant-collapse-header {
    padding-left: 32px !important;
    text-transform: uppercase;
    color: @fox-sidebar-header-color !important;

    i {
      left: 12px !important;
      font-size: 10px !important;
    }
  }
}

// ----------
// DataExport
// ----------

.app-DataExport {
  &--selected {
    .app-DataExport__header {
      background-color: @fox-sidebar-background-lighter;
      background-color: @qbi-color-5;
      &:hover {
        .app-DataExport__header-name {
          color: @qbi-color-white;
        }
      }
    }
  }

  &__header {
    .text-ellipsis();
    align-items: center;
    cursor: pointer;
    position: relative;
    height: 50px;
    padding: 9px 12px;
    color: @fox-sidebar-text-color;
    background-color: @fox-sidebar-background;

    &-icon {
      width: 12px;
      text-align: center;
      margin-right: 8px;
      &--error {
        color: @qbi-error-color;
        position: relative;
        top: -1px;
      }
    }

    &-name,
    &-rowcount {
      line-height: 15px;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    &-name {
      .ant-color-transition();
    }

    &-rowcount {
      .metadata-text();
    }

    .ant-badge-status-text {
      margin-left: 0;
    }

    .app-ShowSqlInEditorButton {
      display: none;
      position: absolute;
      top: 11px;
      right: 12px;
    }

    &:hover {
      .app-DataExport__header-name {
        color: @fox-sidebar-text-color-light;
      }
      .app-ShowSqlInEditorButton {
        display: block;
      }
    }
  }
}
