// General customizations that are either more convenient to do here or hard
// to do inside component specific styles.

// Popovers are attached straight to root of the app, so it's not possible to
// style them inside specific components.
.ant-popover-inner {
  // Make the popover stand out better from white background.
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.45);
}

.ant-select-dropdown-menu-item-group-title {
  text-transform: uppercase;
  font-size: 10px;
}
