@import './OrganizationSelector/organization-selector';

.app-Topbar {
  display: flex;
  background-color: @topbar-background;
  justify-content: space-between;

  &__navigation-link-fox,
  &__navigation-link-front {
    .topbar-text();

    &:focus {
      text-decoration: none;
    }
  }

  &__navigation-link-fox {
    &--active {
      color: @fox-primary-color;
    }

    &--active:hover {
      color: @fox-primary-color;
    }
  }

  &__navigation-link-front {
    &--active {
      color: @front-primary-color;
    }

    &--active:hover {
      color: @front-primary-color;
    }
  }

  &__switch {
    margin-left: 10px;
    margin-right: 10px;

    .ant-switch {
      background-color: @fox-primary-color;
      &:before,
      &:after {
        background-color: @qbi-color-white;
      }
    }

    .ant-switch-checked {
      background-color: @front-primary-color;
    }
  }

  &-user {
    .topbar-text();
    display: flex;
    flex-grow: 0;
    align-items: center;
    margin-left: auto;
    margin-right: 20px;
    cursor: pointer;

    & &-avatar {
      margin-left: 14px;
      background-color: @topbar-icon-background;
      i {
        font-size: 18px !important;
      }
    }
    &-dropdown {
      .dropdown();
    }
  }
}
