.app-TableauView {
  height: 100%;
  overflow-y: hidden;

  > div:not(.ant-alert) {
    height: 100%;
  }

  &-loading {
    .loading(@front-primary-color);
  }
}
