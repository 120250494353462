.app-SqlEditor {
  position: relative;

  // Background colors
  // -----------------

  .ace_editor {
    background-color: @sql-editor-background !important;

    .ace_gutter {
      background-color: @sql-editor-gutter-background !important;

      &-active-line {
        background-color: @sql-editor-gutter-background !important;
      }
    }
  }

  // Text colors
  // -----------

  .ace-sqlserver {
    .ace_keyword {
      color: @sql-editor-keyword-color;
    }
    .ace_support.ace_function {
      color: @sql-editor-function-color;
    }
    .ace_storage {
      color: @sql-editor-data-type-color;
    }
    .ace_string,
    .ace_string.ace_regex {
      color: @sql-editor-string-color;
    }
    .ace_constant.ace_numeric {
      color: @sql-editor-number-color;
    }
    .ace_keyword.ace_operator {
      color: @sql-editor-operator-color;
    }
    .ace_comment {
      color: @sql-editor-comment-color;
    }
    .ace_keyword.ace_qbi {
      color: @sql-editor-custom-color;
    }
  }

  // Elements
  // --------

  &__action-bar {
    background-color: @sql-editor-actions-background;
    padding: 8px @padding-md;
  }

  &__run-button {
    width: 140px;
    margin-right: @padding-md;
  }

  &__save-button {
    width: 140px;
    margin-right: @padding-md;
  }
}
