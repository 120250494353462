.app-Pivot {
  height: 100%;

  &-loading {
    .loading();
  }

  &__empty-message {
    padding: 16px;
  }
}
