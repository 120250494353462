.rst__tree {
  .rst__rowWrapper {
    padding: 5px 10px 5px 0;
  }

  .rst__moveHandle,
  .rst__rowContents {
    box-shadow: none;
    border-radius: 0;
  }

  .rst__moveHandle {
    flex: 0 0 44px;
  }

  .rst__rowContents {
    display: flex;
    flex: 1;
  }

  // Allow the react-sortable-tree node content to expand to full size.
  .rst__rowLabel {
    flex: 1;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}
