.mobile(@rules) {
  @media (max-width: (@tablet-width - 1px)) {
    @rules();
  }
}
.not-mobile(@rules) {
  @media (min-width: @tablet-width) {
    @rules();
  }
}

.ios-momentum-scrolling() {
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
}

.grabbable {
  cursor: move; /* fallback if grab cursor is unsupported */
  cursor: grab;
  &:active {
    cursor: grabbing;
  }
}

.text-ellipsis() {
  display: flex;
  > div {
    flex: 1;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}

.large-icon() {
  font-size: 20px;
  font-weight: bold;
}

.ant-color-transition() {
  transition: color 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.loading(@color: @primary-color) {
  display: flex;
  justify-content: center;
  height: 100%;

  // Instead of fixed padding-top, "align-items: center" was used before.
  // That caused a problem when rendering iframe below loading view: the
  // loading view suddenly jumped upwards when iframe was rendering.
  // This solved the problem and for uniformity it's used not only with iframes
  // but everywhere by this mixin.
  padding-top: 150px;

  > .ant-spin {
    margin-right: 20px;
    i {
      background-color: @color;
    }
  }
}

.dropdown() {
  top: -4px;
  color: @text-color;
  border-radius: 0;
  border: 1px solid @border-color;

  .ant-dropdown-menu-item {
    padding: 10px 20px;
  }
}

.sidebar-collapse-panel(@hover-selector: 'span') {
  .ant-collapse {
    border-radius: 0;

    .ant-collapse-item {
      background-color: @fox-sidebar-background;
      border-color: @fox-sidebar-background;
    }

    .ant-collapse-header {
      padding: 9px @sidebar-padding !important;
      background-color: @fox-sidebar-background;
      color: @fox-sidebar-text-color;
      .text-ellipsis();

      &:hover {
        @{hover-selector} {
          color: @fox-sidebar-text-color-light;
        }
      }
    }

    .ant-collapse-item-active .ant-collapse-header {
      color: @fox-sidebar-text-color;
    }

    .ant-collapse-content-box {
      padding: @sidebar-padding;
      background-color: @fox-sidebar-background-lighter;
      color: @fox-sidebar-text-color;
    }
  }
}

// Use for coloring ant menus with submenus.
.menu-colors(
  @menu-background: @qbi-background-darker;
  @submenu-background: @qbi-background-dark;
  @menu-text-color: @text-color;
  @menu-item-background-active: @primary-color;
  @menu-item-text-color-active: @qbi-color-white;
  @menu-item-text-color-hover: @qbi-color-8
) {
  .ant-menu {
    color: @menu-text-color;
    background-color: @menu-background;
  }

  .ant-menu-inline-collapsed {
    background-color: @menu-background;
  }
  .ant-menu-sub {
    background-color: @submenu-background;
  }
  .ant-menu-submenu-title {
    background-color: @menu-background;
    .ant-menu-submenu-arrow {
      &:before,
      &:after {
        background-image: none;
        background-color: @menu-text-color;
      }
    }
    &:hover {
      color: @menu-item-text-color-hover;
      .ant-menu-submenu-arrow {
        color: @menu-item-text-color-hover;
        &:before,
        &:after {
          background: @menu-item-text-color-hover;
        }
      }
    }
  }
  .ant-menu-item {
    color: @menu-text-color;
    background-color: @submenu-background;

    &.ant-menu-item-selected {
      color: @menu-item-text-color-active;
      background-color: @menu-item-background-active;
      &:hover {
        color: @menu-item-text-color-active;
      }
    }

    &:hover {
      color: @menu-item-text-color-hover;
    }
  }
}

.topbar-text() {
  font-size: @topbar-font-size;
  font-weight: 600;
  color: @qbi-color-6;
  letter-spacing: 0.12px;
  transition: all 0.2s ease-in-out;

  &:hover {
    color: @qbi-color-1;
  }
}

.modal-footer() {
  border-top: 1px solid @modal-border-color;
  padding: 10px 16px;
  text-align: right;
  border-radius: 0 0 4px 4px;
  margin-left: -@modal-padding;
  margin-right: -@modal-padding;
  margin-top: @modal-padding;
  margin-bottom: -@modal-padding;

  button + button {
    margin-left: 8px;
  }
}

.ant-button-color(
  @background-color: @qbi-color-blue;
  @text-color: @qbi-color-white
) {
  background-color: @background-color;
  border-color: @background-color;
  color: @text-color;

  &:hover,
  &:focus {
    background-color: lighten(@background-color, 10%);
    border-color: lighten(@background-color, 10%);
    color: @text-color;
  }
}

.reset-button() {
  overflow: visible;
  margin: 0;
  padding: 0;
  border: 0;
  background: transparent;
  font: inherit; // Inherit font settings
  line-height: normal; // Override line-height to avoid spacing issues
  cursor: pointer; // Buttons don’t make the cursor change in all browsers
  -moz-user-select: text; // Make button text selectable in Gecko

  /* Remove mystery padding in Gecko browsers.
   * See https://bugzilla.mozilla.org/show_bug.cgi?id=140562
   */
  &::-moz-focus-inner {
    padding: 0;
    border: 0;
  }

  &:hover,
  &:focus {
    color: inherit;
  }
}

.login-page-button() {
  padding: 10px 30px;
  border-radius: 20px;
  background-color: @qbi-color-blue;
  transition: all 200ms ease-in-out;
  font-size: 16px;
  font-weight: 600;
  line-height: 20px;
}

.login-page-button-white() {
  .login-page-button();
  border-style: solid;
  border-width: 1px;
  border-color: @qbi-color-blue;
  background-color: #fff;
  transition: all 800ms cubic-bezier(0, 0.907, 0.38, 1.223);
  color: @qbi-color-blue;

  &:hover {
    background-color: @qbi-color-blue;
    box-shadow: 1px 1px 6px 1px rgba(2, 121, 255, 0.37);
    color: #fff;
  }
}

.metadata-text() {
  font-size: @qbi-font-size-small;
  line-height: 15px;
  margin-top: 2px;
  color: @fox-sidebar-text-color-light;
  letter-spacing: 1px;
}
