@import './ColumnEditor/column-editor';

.app-TableView {
  height: 100%;
  position: relative;

  &__loading {
    .loading(@front-primary-color);
  }
}
