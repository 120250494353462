@import './DashboardView/dashboard-view';
@import './FrameView/frame-view';
@import './PivotView/pivot-view';
@import './TableauView/tableau-view';
@import './TableView/table-view';
@import './ViewTopbar/view-topbar';

.app-View {
  height: 100%;
  background-color: @qbi-color-white;

  &__content {
    height: calc(~'100vh - ' @layout-header-height - @view-topbar-height);
  }
}
