.app-DashboardItemEditor {
  &__modal {
    top: 20px;
  }

  &__data-export {
    margin-bottom: 20px;
    display: flex;

    &-label {
      line-height: 24px;
      font-size: 12px;
      margin-right: 12px;
    }

    .app-DataExportSelect {
      width: 240px;
    }
  }
}
