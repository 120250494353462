@import './FoxPage/fox-page';
@import './FrontPage/front-page';
@import './Topbar/topbar';

.app-Organization {
  height: 100%;

  &__topbar-container {
    padding: 0;
  }

  &__content {
    height: calc(~'100vh - ' @layout-header-height);
    background-color: @qbi-color-white;

    .ios-momentum-scrolling();
  }
}
