@import './DashboardBox/dashboard-box';

.app-DashboardItem {
  position: relative;
  margin-bottom: 20px;

  &--empty {
    background-color: @qbi-color-10;
    height: 200px;
  }

  &__edit-button {
    display: none;
    position: absolute;
    top: 12px;
    left: 12px;
  }

  &:hover {
    .app-DashboardItem__edit-button {
      display: block;
    }
  }
}
