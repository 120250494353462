.app-DashboardBox {
  @value-size: 50px;
  @value-size-small: 42px;

  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 170px;
  padding: 0 10px;
  background-color: @qbi-color-blue-light;

  &__title {
    text-align: center;
    font-size: 18px;
  }

  &__value {
    text-align: center;
    font-size: @value-size;
    line-height: @value-size;
    margin-top: 8px;
  }

  &__comparison {
    text-align: center;
    margin-top: 2px;
  }

  &--size-small {
    height: 130px;

    .app-DashboardBox__title {
      font-size: 16px;
      text-align: left;
    }

    .app-DashboardBox__value {
      display: inline-block;
      text-align: left;
      font-size: @value-size-small;
      line-height: @value-size-small;
      margin-top: 8px;
    }

    .app-DashboardBox__comparison {
      display: inline-block;
      text-align: left;
      margin-top: 6px;
      margin-left: 12px;
    }
  }
}
