.app-OrganizationSelector {
  @logo-size: 32px;

  background-color: @topbar-background;

  &-container {
    padding-left: 10px;
    padding-right: 10px;
    height: @layout-header-height;
    display: flex;
    justify-content: center;
  }

  &__dropdown {
    .dropdown();
  }

  &-current {
    .topbar-text();
    display: flex;
    align-items: center;
    justify-content: center;

    &:focus {
      text-decoration: none;
    }

    &-logo {
      display: flex;
      height: @logo-size;
      width: @logo-size;
    }
    &-name {
      line-height: 11px;
      letter-spacing: 0;
      margin-left: 8px;
    }
    &-icon {
      padding-left: 10px;
      padding-right: 5px;

      .anticon {
        margin-top: 18px;
      }
    }
  }

  &-logo,
  &-no-logo {
    height: @logo-size;
    width: @logo-size;
    max-height: @logo-size;
    max-width: @logo-size;
    border-radius: 50%;
  }
  &-logo.app-OrganizationSelector-logo-default {
    border-radius: 0;
  }
  &-no-logo {
    background-color: @topbar-icon-background;
  }

  &-collapsed&:not(&-mobile) & {
    &-current {
      &-name {
        display: none;
      }
      &-icon {
        padding-left: 5px;
      }
    }
  }
  &-disabled & {
    &-current {
      color: @text-color;

      &-icon {
        display: none;
      }
    }
  }

  &-item-link {
    padding-top: 9px !important;
    padding-bottom: 9px !important;
  }

  &-admin-page-item {
    border-bottom: 1px solid @border-color;
  }
  &-admin-page-icon {
    width: @logo-size;
    font-size: 20px !important;
    margin-right: 0 !important;
    position: relative;
    top: 3px;
  }

  &-item-name {
    margin-left: 7px;
  }

  &__section-header {
    color: @qbi-text-color-light;
    text-transform: uppercase;
    padding: 10px 20px;
    font-size: 10px;
  }
}
