.app-SourceTableGroup {
  background-color: @qbi-color-1;
  background-color: @fox-sidebar-background;
  padding-bottom: 20px;

  &__header {
    .text-ellipsis();
    padding: 4px @sidebar-padding 0 @sidebar-padding;
    text-transform: uppercase;
    color: @fox-sidebar-header-color;
  }

  .app-GroupDropdown {
    flex: 0 0 20px;
    // Make sure the button doesn't increase the overall header height.
    margin-bottom: -4px;

    .app-GroupDropdown__button {
      width: 20px !important;
      height: 20px !important;
      margin-bottom: -4px !important;
      min-width: 20px !important;

      .anticon svg {
        font-size: 15px;
      }
    }
  }

  &__info {
    .text-ellipsis();
    padding: 0 @sidebar-padding 4px @sidebar-padding;
    font-style: italic;
    font-size: @qbi-font-size-small;
    line-height: 15px;
    margin-top: 2px;
    color: @fox-sidebar-text-color-light;
  }

  .sidebar-collapse-panel(
    @hover-selector: ~'.app-SourceTables__table-name'
  );

  .ant-collapse-header {
    position: relative;

    .app-ShowSqlInEditorButton {
      display: none;
      position: absolute;
      top: 11px;
      right: @sidebar-padding;
    }

    &:hover {
      .app-ShowSqlInEditorButton {
        display: block;
      }
    }
  }

  &__table {
    &-name,
    &-rowcount {
      line-height: 20px;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    &-name {
      .ant-color-transition();
      font-size: @qbi-font-size;
      line-height: 15px;
    }

    &-rowcount {
      .metadata-text();
    }
  }

  &__table-columns {
    padding-left: @sidebar-padding;
    color: @qbi-color-9;
    line-height: 16px;
  }

  &__table-column-metadata {
    .metadata-text();
    margin-top: 0px;
    margin-bottom: 8px;
  }

  &__table-column + &__table-column {
    margin-top: 4px;
  }
}
