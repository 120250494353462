.app-DataGrid {
  @row-number-column-background: @qbi-color-11;

  height: 100%;
  position: relative;

  &-loading {
    .loading();
  }

  &-settings {
    position: absolute;
    top: 1px;
    left: 0px;
    padding: 5px;
    z-index: 100;
    font-size: 20px;
    cursor: pointer;
    background-color: @row-number-column-background;
    border-bottom: 1px solid @row-number-column-background;
  }

  &-settings-dropdown {
    .dropdown();
    top: -5px;
  }

  > .dx-widget > .dx-datagrid {
    .dx-header-filter:not(.dx-header-filter-empty) {
      color: @active-filter-color;
    }

    .dx-row {
      > td {
        font-size: @data-grid-font-size;
        padding: 6px;

        &.dx-editor-cell {
          padding: 3px;
        }
      }

      &.dx-header-row > td {
        font-size: @data-grid-header-font-size;
        padding: 8px;
        color: @text-color;
        font-weight: 500;
      }

      &.dx-data-row.dx-state-hover,
      &.dx-data-row.dx-state-hover > td {
        // Needs to be rgba for scrollbar to be visible in Chrome
        background-color: rgba(0, 0, 0, 0.1) !important;
      }
    }

    .dx-datagrid-filter-row .dx-menu {
      top: -2px;
    }

    .dx-texteditor-input {
      font-size: @data-grid-font-size;
      padding: 6px 6px 6px 32px;
      min-height: 20px;
    }

    // Table row-number styles
    // -----------------------

    .app-DataGrid__row-number-cell {
      color: @qbi-color-8;
      background-color: @row-number-column-background;
      font-size: 8px !important;
      vertical-align: middle;
    }
    .dx-datagrid-filter-row {
      td:first-child {
        background-color: @row-number-column-background;
      }
    }

    // Alternating row colors
    // ----------------------

    .dx-row-alt > td,
    .dx-row-alt > tr > td {
      // Needs to be rgba for scrollbar to be visible in Chrome
      background-color: rgba(0, 0, 0, 0.05);
    }
  }

  // Styles when there are no row-number column (external tables)
  // ------------------------------------------------------------

  &--no-row-number-column {
    .app-DataGrid-settings {
      border: 1px solid @qbi-color-1;
    }

    > .dx-widget > .dx-datagrid {
      .dx-datagrid-filter-row {
        td:first-child {
          background-color: #fff;
        }
      }
    }
  }
}

// Column chooser popup (the element is outside of .app-DataGrid)
// --------------------------------------------------------------

.dx-datagrid-column-chooser-list {
  // Dont' show disabled columns (row number column and band).
  .dx-state-disabled {
    display: none;
  }
}
