.app-DataExportGroupEditor {
  &__modal {
    top: 20px;
  }
}

.app-SortableGroups {
  &__add-group-button {
    float: right;
    height: 24px;
    background-color: @qbi-color-blue-light;
  }

  &__content {
    height: 430px;
    margin-right: 120px;
  }

  .rst__rowWrapper {
    padding: 5px 10px 5px 0;
  }

  &__node {
    width: 280px;

    &-input {
      width: 100%;
      &--error {
        border-color: @qbi-color-red;
      }
    }

    &-remove-button {
      .anticon {
        margin-top: 2px;
      }
    }
  }

  &__group {
    .rst__moveHandle {
      background-color: @qbi-color-blue-light;
    }
  }

  &__export {
    .rst__moveHandle {
      background-color: @qbi-color-green;
    }
  }
}
