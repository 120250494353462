.app-FrameView {
  height: 100%;
  overflow-y: hidden;

  &-loading {
    .loading(@front-primary-color);
  }

  &-iframe-loading {
    visibility: hidden;
  }
}
