// Override Ant Design styles.
.ant-modal {
  .ant-confirm-info .ant-confirm-body > .anticon {
    color: @info-color;
  }
}

.app-help-modal {
  &-email {
    font-weight: bold;
  }
}
