@import './DashboardEditor/dashboard-editor';
@import './DashboardItem/dashboard-item';
@import './DashboardItemEditor/dashboard-item-editor';

.app-DashboardView {
  @dashboard-padding: 12px;

  height: 100%;
  padding: @dashboard-padding;

  &__loading {
    .loading(@front-primary-color);
  }

  &__topbar {
    display: flex;
    align-content: space-between;
  }

  &__filters {
    flex: 1;
    display: flex;
    padding-bottom: @dashboard-padding;
  }

  &__edit-tools {
    margin-left: @dashboard-padding;
  }

  .app-DashboardFilter {
    flex: 1;

    & + .app-DashboardFilter {
      margin-left: @dashboard-padding;
    }
  }

  &__row {
    margin-bottom: 30px;
  }
}
