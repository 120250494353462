.app-ShowSqlInEditorButton {
  height: 28px;
  border-radius: 0;
  background-color: @fox-sidebar-background-lighter;
  border: 1px solid @qbi-color-5;

  &:hover {
    background-color: @qbi-color-5;
  }
}
