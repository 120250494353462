.app-CopyInput {
  display: flex;

  &__copyable-text {
    code {
      display: none;
    }

    svg {
      font-size: 14px;
      margin-top: 9px;
    }
  }
}
