@import './DataDisplay/data-display';
@import './SqlEditor/sql-editor';

.app-EditorTabs {
  @new-tab-button-width: @tabs-card-height;

  > .ant-tabs {
    > .ant-tabs-nav {
      margin-bottom: 0;
      background-color: @editor-tab-bar-background;
      border-bottom: none;

      .ant-tabs-tab {
        color: @editor-tab-card-text-color;
        height: @tabs-card-height !important;
        border: none;
        border-radius: 0;
        border-right: 1px solid @editor-tab-card-background-hover;
        padding: 0 18px;

        .ant-tabs-tab-remove {
          color: @qbi-color-7 !important;
          font-size: @qbi-font-size-small;
          &:hover {
            color: @qbi-color-black !important;
          }
        }

        &:hover {
          background-color: @editor-tab-card-background-hover;
        }
      }

      .ant-tabs-tab-active {
        background-color: @editor-tab-card-background-active !important;
        color: @editor-tab-card-text-color-active !important;
      }
    }

    .ant-tabs-nav-add {
      border: none !important;
      border-bottom: 1px solid #f0f0f0 !important;
      font-size: 14px;

      &:hover {
        background-color: @editor-tab-card-background-hover;
      }
    }
  }

  .panelWrapper + .divider {
    background-color: @sql-editor-actions-background !important;
  }

  // These fixes are needed for the react-panelgroup to always have the same
  // height inside ant tabs.
  > .ant-tabs > .ant-tabs-content {
    > .ant-tabs-tabpane {
      height: 100%;
    }
    > .ant-tabs-tabpane-inactive {
      display: none;
    }
  }
}
