@import './Pivot/pivot';
@import './Table/table';

.app-DataDisplay {
  width: 100%;
  background-color: @data-display-background;

  > .ant-tabs {
    > .ant-tabs-nav {
      background-color: @data-display-tab-bar-background;
    }

    .ant-tabs-tab {
      padding: 8px 16px !important;
    }
  }

  &__error-message {
    font-weight: bold;
  }
}
