.app-AuthAccess,
.app-FrontAccess,
.app-SharedUrlAccess {
  border: 2px solid @qbi-color-9;
  padding: 8px;
  margin-bottom: 16px;

  &--enabled {
    border: 2px solid @qbi-color-blue;
  }

  &__header {
    display: flex;
    align-items: center;
    margin-bottom: 8px;
    h4 {
      flex: 1;
      margin: 0;
      text-transform: uppercase;
    }
  }

  &__buttons {
    margin-bottom: 8px;
    button + button {
      margin-left: 8px;
    }
  }

  &__steps {
    padding-left: 12px;

    li {
      padding-left: 12px;
    }

    li + li {
      margin-top: 32px;
    }

    code {
      margin-left: 0;
      line-height: 22px;
    }
  }

  &__substeps {
    padding-left: 16px;
    list-style: disc;
  }

  &__action {
    width: 100%;
    margin-bottom: 8px;

    th {
      width: 110px;
      font-weight: normal;
    }

    td {
      padding-left: 8px;
    }
  }
}

.app-DataExportConnections {
  padding-left: 18px;
  margin-bottom: 16px;

  &--empty {
    list-style: none;
    padding-left: 0;

    li {
      color: @qbi-text-color-light;
      font-size: @qbi-font-size-small;
    }
  }

  &__connection {
    margin-bottom: 4px;
  }

  &__last-fetched-at {
    color: @qbi-text-color-light;
    font-size: @qbi-font-size-small;
  }
}
