// Third party styles
// ------------------
@import '~antd/dist/antd.less';
@import '~devextreme/dist/css/dx.common.css';
@import '~devextreme/dist/css/dx.light.compact.css';
@import '~filepond/dist/filepond.min.css';
@import '~react-sortable-tree/style.css';

// Variables & mixins
// ------------------
@import './variables';
@import './mixins';

// Third party customizations
// --------------------------
@import './ant-customizations';
@import './react-sortable-tree';
@import './react-split-pane';

// Our components
// --------------
@import './notifications';
@import '~app/screens/screens';
@import '~app/shared/shared';
