@import './login-topbar';

.app-Login {
  @login-field-width: 400px;
  @login-field-height: 38px;

  height: 100vh;
  background-color: @qbi-color-white;
  overflow: hidden;

  &__container {
    height: calc(~'100vh - ' @login-topbar-height);
    background-image: linear-gradient(
        180deg,
        hsla(0, 0%, 100%, 0.7),
        hsla(0, 0%, 100%, 0.7)
      ),
      url('~app/assets/images/login-background.jpg');
    background-repeat: repeat;

    @media (max-width: 992px) {
      height: 100%;
      align-items: flex-start;
      padding-top: 40px;
    }

    width: 100%;
    padding: 20px;
    display: flex;
    justify-content: center;
    align-items: center;

    a {
      font-size: 12px;

      &:hover {
        text-decoration: underline;
      }
    }
  }

  &__content {
    background-color: #f0f0fa;
    padding: 20px 40px;
    border-radius: 2px;

    @media (max-width: 440px) {
      width: 100%;
    }
  }

  &__header {
    font-size: 42px;
    font-weight: 900;
    text-align: center;
    color: @qbi-color-black;

    &-highlight {
      color: @qbi-color-blue;
    }
  }

  &__form {
    padding: 20px 0;
    max-width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;

    .ant-form-item {
      margin-bottom: 0;
      padding-bottom: 20px;
    }

    .ant-form-item-label {
      padding-bottom: 5px !important;
      label {
        font-size: 14px;
        color: rgb(64, 64, 64);
        line-height: 20px;
      }
    }

    &-actions {
      display: flex;
      justify-content: space-between;
      margin-top: 10px;
      line-height: @login-field-height;
    }

    &-submit-button {
      .login-page-button();
    }
  }
  &__field {
    width: @login-field-width;
    max-width: 100%;
  }
  &__divider {
    text-transform: uppercase;
    width: @login-field-width;
  }
  &__google-login-container {
    display: flex;
    justify-content: center;
    margin-top: 30px;
  }
  &__contact {
    margin-top: 70px;
    line-height: 30px;
    text-align: center;
    font-size: 12px;
  }

  &__error {
    margin-bottom: 0;
  }
}
