@import './DataExportAccess/data-export-access';

.app-DataExportDetails {
  width: 430px;

  &__wrapper {
    position: absolute;
    top: 6px;
    left: 4px;
  }

  &__alert,
  &__info {
    border-radius: @qbi-border-radius;
    margin-left: -16px;
    margin-right: -16px;
    margin-top: -12px;
    margin-bottom: 12px;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    padding-left: 46px;

    &-icon {
      font-size: 16px;
      top: 16px;
    }
  }

  &__alert {
    border-bottom: 1px solid @qbi-error-color;
  }

  &__header-section {
    display: flex;
    position: relative; // Needed for DataExportSettings delete-confirm.

    &-content {
      flex: 1;
    }

    .app-DataExportSettings {
      margin-right: 8px;
    }
  }

  &__header {
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 0;
  }

  &__data-updated-at {
    font-size: 11px;
    color: @qbi-text-color-light;
    margin-bottom: 8px;
  }

  &__description {
    margin-bottom: 8px;
  }

  &__created-at,
  &__edited-at {
    color: @qbi-text-color-light;
    font-size: @qbi-font-size-small;
  }

  &__subheader {
    margin-top: 40px;
    margin-bottom: 16px;
    font-size: 14px;
  }
}

// ------------------
// DataExportSettings
// ------------------

.app-DataExportSettings {
  z-index: 100; // Make sure delete-confirm get's rendered on top of other elements.

  &__button {
    line-height: 32px;

    .anticon {
      vertical-align: 0;
    }
  }

  &__dropdown {
    .dropdown();
  }

  &__delete-confirm {
    position: absolute;
    left: 0;
    right: 0;
    top: 38px;

    button + button {
      margin-left: 8px;
    }
  }
}

// ------------------
// DataExportDownload
// ------------------

.app-DataExportDownload {
  display: flex;

  &__button {
    flex: 1;
  }

  &__button + &__button {
    margin-left: 8px;
  }
}
