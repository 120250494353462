.app-DataSourceUpload {
  &__button {
    font-size: @qbi-font-size-small;
    padding: 4px 8px;
    height: 28px;
  }

  &__modal-content {
    position: relative;
    margin-bottom: -24px;
  }

  &__method-select {
    position: absolute;
    left: 0;
    bottom: 8px;
  }
}

.app-FileUpload {
  &__sub-header {
    margin-bottom: 6px;
  }

  &__actions {
    .modal-footer();
  }
}

.app-ApiUpload {
  &__section {
    margin-bottom: 20px;
  }

  &__api-description-header-row {
    background-color: @qbi-color-9;
  }

  &__header-name {
    text-align: right;
  }

  &__api-description-row {
    padding: 8px 0;
    & + & {
      border-top: 1px solid @border-color;
    }
  }

  &__field-name {
    font-weight: bold;
    text-align: right;
  }

  &__field-type {
    font-style: italic;
    text-align: right;
  }

  &__actions {
    .modal-footer();
  }
}
