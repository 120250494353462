@import './AddDataSource/add-data-source';
@import './DataExportDetails/data-export-details';
@import './DataExportGroupEditor/data-export-group-editor';
@import './DataExportModal/data-export-modal';
@import './DataExports/data-exports';
@import './EditorTabs/editor-tabs';
@import './SourceTables/source-tables';

.app-FoxPage {
  position: relative;
  height: 100%;

  &__left-sidebar,
  &__right-sidebar {
    background-color: @fox-sidebar-background-lighter;
    color: @fox-sidebar-text-color;
    overflow-y: auto;
    width: 100%;
    height: calc(~'100vh - ' @layout-header-height);
  }

  &__sidebar-header {
    .text-ellipsis();
    background-color: @fox-sidebar-background;
    padding: @sidebar-padding;
    font-size: @qbi-font-size-big;
    color: @fox-sidebar-text-color;
    margin-bottom: 0;
  }

  &__sidebar-row {
    .text-ellipsis();
    background-color: @fox-sidebar-background;
    padding: 0 @sidebar-padding;
  }

  &__sidebar-header + &__sidebar-row {
    margin-top: -@sidebar-padding;
    padding-bottom: @sidebar-padding;
  }

  .app-AddDataSource {
    margin-top: 12px;
    text-align: center;
    padding-bottom: 12px;
  }

  .app-DataExportGroupEditor {
    flex: 0 0 24px;
    // Make sure the button doesn't increase the overall header height.
    margin-bottom: -5px;
  }
}
