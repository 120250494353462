.app-MeasureSelect {
  margin-left: 17px;
  display: flex;
  align-items: center;

  &-hidden {
    display: none;
  }

  &-item {
    width: 200px;
    margin-right: 5px;
    margin-top: 3px;
    margin-bottom: 3px;
    color: @qbi-color-black;
  }

  &-add,
  &-remove {
    padding: 10px 3px;
    cursor: pointer;
  }
}
